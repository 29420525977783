<template>
  <div>
    <div class="card">
      <a-button @click="seeAll" style="margin-bottom: 10px;">一键已读</a-button>
      <a-skeleton v-if="loading" />

      <div v-else>
        <a-empty v-if="list.length === 0" />

        <table v-else class="table">
          <tr class="header">
            <td>消息标题</td>
            <td>消息内容</td>
            <td>发送时间</td>
            <td>状态</td>
            <td class="center">操作</td>
          </tr>

          <tr v-for="item in list" :key="item.id" class="row">
            <td :style="item.isRead?'':'font-weight:bold'">{{ item.title }}</td>
            <td :style="item.isRead?'':'font-weight:bold'">{{ item.content }}</td>
            <td :style="item.isRead?'':'font-weight:bold'">{{ item.createAt }}</td>
            <td :style="item.isRead?'':'font-weight:bold'">
              <span v-if="item.isRead">已读</span>
              <span v-else>未读</span>
            </td>
            <td class="control center">
              <a @click.prevent="see(item)">查看</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchList, read, readList } from "@/api/message";

export default {
  data() {
    return {
      loading: false,
      list: [],
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: 1,
        pageSize: 100,
      })
        .then((res) => {
          if (res && Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    see(item) {
      read({
        id: item.id,
      });

      if (item.extra) {
        const obj = JSON.parse(item.extra);
        this.$router.push(`/task?projectId=${obj.projectId}&name=${obj.name}`);
      } else {
        this.$router.push("/task");
      }
    },

    seeAll() {
      const that = this;
      this.$confirm({
        title: "是否确认已读？",
        okText: "是",
        okType: "danger",
        cancelText: "否",
        onOk() {
          readList({
            idList: that.list.map(v=>v.id)
          }).then(() => {
            that.getList();
          });
        },
      });
    }
  },
};
</script>

<style lang="less" scoped>
.card {
  background-color: #fff;
  box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
  border-radius: 20px;
  padding: 40px;
}

.table {
  width: 100%;
  .header {
    opacity: 0.7;

    td {
      padding: 16px;
      background: #ebf2fc;
      font-weight: 400;
      color: #9ca4b1;
      font-size: 16px;
    }
    td:first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    td:last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }

  .row {
    border-bottom: 1px solid rgba(207, 220, 238, 0.71);

    td {
      padding: 16px;
    }
  }
}
</style>